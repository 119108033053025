import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
// import { DropDown, Menu } from '../components/common/sidebarMenu';
import { UserContext } from "../context/theme";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

function Header() {
  const { setAuth, setUserInfo } = useContext(UserContext);
  const{userInfo}=useContext(UserContext);
  const Logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };

  const getProfile = async (id) => {
    await authAxios({
      method: "GET",
      url: `${ROOT_URL}/v1/admins/get/${id}`,
    })
      .then((res) => {
        setUserInfo(res.data.data);
      })
      .catch((error) => {
        common.error(error);
      });
  };

  useEffect(() => {
    let userId = reactLocalStorage.get("userId");
    if (userId) {
      getProfile(userId);
    }
  }, []);
  return (
    <>
      <div className="left-sidebar-wrapper">
        <nav className="sidebar">
          <div className="sidebar-header text-center">
            <NavLink activeclassname="isActive" to="/">
              <img
                src={`/assets/images/logo-white.png`}
                className="img-fluid"
                alt="logo"
              />
            </NavLink>

            <strong>
              <NavLink activeclassname="isActive" to="/">
                <span>RP</span>
                {/* <img src={`/assets/images/logo-small.png`} alt="logo" /> */}
              </NavLink>
            </strong>
          </div>

          
          <div className="left-nav-menu">

          <DropDownMenu>
                <Menu path="/" title="Dashboard" icon="icon-wrap icon-home" />
                {/* <Dropdown icon="icon-wrap icon-user" title="Admin">
                    <Menu path={`/create-admin`} title="Create Admin" icon="fas fa-plus" />
                    <Menu  path={`/admin`} title="Admin List" icon="fas fa-list" />
                </Dropdown>            */}
                <Menu  path={`/admin`} title="Admins" icon="fas fa-tree" />     
                <Menu  path={`/user`} title="Users" icon="fas fa-user" />     
                <Menu  path={`/comments`} title="Comments" icon="fas fa-comment" />  
                <Menu  path={`/polls`} title="Polls" icon="fas fa-newspaper" />   
                <Menu  path={`/publisher`} title="Publishers" icon="fas fa-users" />
                <Menu  path={`/category`} title="Categories" icon="fas fa-list" />
                <Menu  path={`/city`} title="Cities" icon="fas fa-map-marker" />
                <Menu  path={`/tags`} title="Tags" icon="fas fa-tag" />
                <Menu  path={`/shorts`} title="Shorts" icon="fas fa-tag" />
                <Menu  path={`/posts`} title="Posts" icon="fas fa-rss" />
                <Menu  path={`/story`} title="Story" icon="fab fa-twitch" />
                <Menu  path={`/quiz`} title="Quiz" icon="fas fa-lightbulb" />
                <Menu  path={`/articleSlider`} title="Article Slider" icon="fas fa-image" />
                <Dropdown icon="icon-wrap icon-user" title="Elections">
                    <Menu path={`/election`} title="Election List" />
                    <Menu path={`/election/party`} title="Party" />
                    <Menu path={`/election/candidate`} title="Candidate" />
                    <Menu path={`/election/constituency`} title="Constituency" />
                    <Menu path={`/election/statistics`} title="Statistics" />
                    <Menu path={`/election/district`} title="District" />
                </Dropdown>   
                {/* <Dropdown icon="fas fa-sliders-h" title="Slider">
                  
                  <Menu  path={`/articleSliderAds`} title="Article Slider Ads" icon="fab fa-buysellads" />
                </Dropdown> */}
                <Menu  path={`/live-news`} title="Live News" icon="fas fa-newspaper" />
                <Menu  path={`/meta-home`} title="Home Page Meta" icon="fas fa-newspaper" />
                <Menu  path={`/ads/list`} title="Ads List" icon="fas fa-newspaper" />
                {/* <Dropdown icon="icon-wrap icon-user" title="Meta">
                    <Menu path={`/meta-home`} title="Home Page Meta" />
                </Dropdown>    */}

            </DropDownMenu>
             
          </div>
          <div className="version">
            <span className="vs-mobile">{common.version()}</span>
            <span className="vs-web">{common.version()}</span>
          </div>
         
        </nav>
      </div>
    </>
  );
}
export default Header;
